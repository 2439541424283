<template>
  <b-modal
    id="changePasswordModal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        變更密碼
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <!-- 舊密碼 -->
        <validation-provider
          #default="{ errors }"
          name="舊密碼"
          rules="required"
        >

          <b-form-group label-for="userPassword-pastPassword">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  舊密碼
                  <span class="text-danger">*</span>
                </label>
              </div>
            </template>

            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="userPassword-pastPassword"
                v-model="userPassword.pastPassword"
                :type="passwordFieldType[0]"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                name="userPassword-pastPassword"
                placeholder="請輸入舊密碼"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon(0)"
                  class="cursor-pointer"
                  @click="togglePassword(0)"
                />
              </b-input-group-append>
            </b-input-group>

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 新密碼 -->
        <validation-provider
          #default="{ errors }"
          name="新密碼"
          rules="required"
        >

          <b-form-group label-for="userPassword-newPassword">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  新密碼
                  <!-- {{ userRegPwdLevel }} -->
                  <span class="text-danger">*</span>
                </label>

                <span>
                  <div
                    v-b-tooltip.hover.v-secondary
                    title="密碼產生器"
                    class="d-flex align-items-center actions-link-btn"
                    @click="() => { $refs.randomGenerator.getData() }"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>
                </span>
              </div>
            </template>

            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="userPassword-newPassword"
                v-model="userPassword.newPassword"
                :type="passwordFieldType[1]"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                name="userPassword-newPassword"
                placeholder="請輸入新密碼"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon(1)"
                  class="cursor-pointer"
                  @click="togglePassword(1)"
                />
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>

          <!-- 密碼驗證 -->
          <div class="power-tips">
            <b-row class="tips">
              <b-col
                class="tips-item"
                :class="(userRegPwdLevel >= 1 && userPassword.newPassword) ? `${passwordStyle(userRegPwdLevel)}` : ''"
              />

              <b-col
                class="tips-item"
                :class="(userRegPwdLevel >= 2) ? `${passwordStyle(userRegPwdLevel)}` : ''"
              />

              <b-col
                class="tips-item"
                :class="(userRegPwdLevel >= 3) ? `${passwordStyle(userRegPwdLevel)}` : ''"
              />

              <b-col
                class="tips-item"
                :class="(userRegPwdLevel >= 4) ? `${passwordStyle(userRegPwdLevel)}` : ''"
              />
            </b-row>

            <b-row class="tips">
              <b-col>
                <div
                  v-if="userRegPwdLevel === 1"
                  class="text-center"
                >
                  <span>弱</span>
                </div>
              </b-col>

              <b-col>
                <div
                  v-if="userRegPwdLevel === 2"
                  class="text-center"
                >
                  <span>中</span>
                </div>
              </b-col>

              <b-col>
                <div
                  v-if="userRegPwdLevel === 3"
                  class="text-center"
                >
                  <span>強</span>
                </div>
              </b-col>

              <b-col>
                <div
                  v-if="userRegPwdLevel === 4"
                  class="text-center"
                >
                  <span>很強</span>
                </div>
              </b-col>
            </b-row>
          </div>
        </validation-provider>

        <random-generator
          ref="randomGenerator"
          department-explorer-id="randomNewPasswordGenerator"
          department-explorer-type="modal"
          :is-hiden-emit="false"
          :use-callback-data="true"
          @call-back-data="(item) => userPassword.newPassword = item"
        />

        <!-- 重複新密碼 -->
        <validation-provider
          #default="{ errors }"
          name="重複新密碼"
          rules="required"
        >

          <b-form-group label-for="userPassword-repeatPassword">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  重複新密碼
                  <span class="text-danger">*</span>
                </label>
              </div>
            </template>

            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="userPassword-repeatPassword"
                v-model="userPassword.repeatPassword"
                :type="passwordFieldType[2]"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                name="userPassword-repeatPassword"
                placeholder="請重複輸入新密碼"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon(2)"
                  class="cursor-pointer"
                  @click="togglePassword(2)"
                />
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
// API
import { ref, watch } from '@vue/composition-api'
import { required, password } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BRow, BCol, BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, BInputGroupAppend,
  BInputGroup, BImg, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
// Component
import formValidation from '@core/comp-functions/forms/form-validation'
import RandomGenerator from '@/layouts/components/Modal/random-modal/RandomModal.vue'

import { useModalSetting } from '../useModal'
import { useAlert } from '@/libs/mixins/index'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BForm,
    // BFormGroup,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // BFormSpinbutton,

    ValidationProvider,
    ValidationObserver,
    RandomGenerator,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
  },
  data() {
    return {
      required,
      password,
      missingFields: [],
      passwordFieldType: ['password', 'text', 'password'],
    }
  },
  computed: {
  },
  methods: {
    // 顯示密碼/隱藏密碼
    togglePassword(index) {
      const newValue = this.passwordFieldType[index] === 'password' ? 'text' : 'password'
      this.passwordFieldType.splice(index, 1, newValue)
    },
    // 顯示密碼/隱藏密碼 - ICON
    passwordToggleIcon(index) {
      return this.passwordFieldType[index] === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },

    // ------------------------------------------------------------------------------------------------
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('changePasswordModal')
        this.resetUserPassword()
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        pastPassword: '舊密碼',
        newPassword: '新密碼',
        repeatPassword: '確認新密碼',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.userPassword[Object.keys(requiredFields)[i]] === null || this.userPassword[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }
      if (this.userPassword.newPassword !== this.userPassword.repeatPassword) {
        this.useAlertToast(false, '新密碼與確認新密碼不一致')
        return
      }

      this.isBusy = true

      const resolveData = {
        pastPassword: this.userPassword.pastPassword,
        newPassword: this.userPassword.newPassword,
      }

      axios('/user/password', {
        method: 'POST',
        headers: {
          'Access-Token': useJwt.getToken(),
        },
        data: {
          ...resolveData,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.$nextTick(() => {
            this.$bvModal.hide('changePasswordModal')
          })
          store.dispatch('api/logout')
            .then(() => {
              localStorage.clear()
              this.$router.replace({ name: 'page-login' })
            })
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // (獲取)資料
    getData() {
      this.resetUserPassword()
      this.$bvModal.show('changePasswordModal')
    },
  },
  setup() {
    localize('tw')

    const {
      isBusy,
      syncObject,
    } = useModalSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankPasswordData = {
      pastPassword: '',
      newPassword: '',
      repeatPassword: '',
    }

    const userPassword = ref(JSON.parse(JSON.stringify(blankPasswordData)))
    const userRegPwdLevel = ref(0)

    const resetUserPassword = () => {
      userPassword.value = JSON.parse(JSON.stringify(blankPasswordData))
      userRegPwdLevel.value = 0
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserPassword)

    const lengthWeakness = psw => {
      const { length } = psw

      if (length <= 5) {
        return {
          message: '你的密碼太短',
          deduction: 40,
        }
      }

      if (length <= 10) {
        return {
          message: '你的密碼可以再長一點',
          deduction: 15,
        }
      }

      if (length <= 15) {
        return {
          message: '',
          deduction: -20,
        }
      }
      return null
    }

    const characterTypeWeakness = (psw, regex, type) => {
      const matches = psw.match(regex) || []

      if (matches.length === 0) {
        return {
          message: `你的密碼沒有 ${type}`,
          deduction: 20,
        }
      }

      if (matches.length <= 2) {
        return {
          message: `你的密碼可以用更多 ${type}`,
          deduction: 5,
        }
      }
      return null
    }

    const repeatCharactersWeakness = psw => {
      const matches = psw.match(/(.)\1/g) || []
      if (matches.length > 0) {
        return {
          message: 'Your password has repeat characters',
          deduction: matches.length * 10,
        }
      }
      return null
    }

    const calculatePasswordStrength = psw => {
      const weaknesses = []
      weaknesses.push(lengthWeakness(psw))
      weaknesses.push(characterTypeWeakness(psw, /[A-Z]/g, '大寫英文'))
      weaknesses.push(characterTypeWeakness(psw, /[a-z]/g, '小寫英文'))
      weaknesses.push(characterTypeWeakness(psw, /[0-9]/g, '數字'))
      weaknesses.push(characterTypeWeakness(psw, /[^0-9a-zA-Z\s]/g, '特殊字元'))
      weaknesses.push(repeatCharactersWeakness(psw))
      return weaknesses
    }

    const passwordCheck = value => {
      const weaknesses = calculatePasswordStrength(value)
      let strength = 100
      weaknesses.forEach(weakness => {
        if (weakness == null) return
        strength -= weakness.deduction
        const messageElement = document.createElement('div')
        messageElement.innerText = weakness.message
      })
      switch (true) {
        case strength > 90: {
          userRegPwdLevel.value = 4
          break
        }
        case strength > 49: {
          userRegPwdLevel.value = 3
          break
        }
        case strength > 20: {
          userRegPwdLevel.value = 2
          break
        }
        default:
          userRegPwdLevel.value = 1
          break
      }
    }

    watch(() => userPassword.value.newPassword, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        passwordCheck(newValue)
      }
    })

    const passwordStyle = state => {
      if (state === 1) return 's1'
      if (state === 2) return 's2'
      if (state === 3) return 's3'
      if (state === 4) return 's4'
      return ''
    }

    return {
      isBusy,

      userPassword,
      userRegPwdLevel,
      passwordStyle,
      passwordCheck,
      blankPasswordData,
      // setPasswordChange,

      resetUserPassword,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>

<!-- 密碼強度-SCSS -->
<style lang="scss" scope>
.power-tips {
  height: 40px;
}
.tips {
  margin: 5px 0 5px 0;
  .tips-item {
    margin-right: 5px;
    height: 8px;
    border-radius: 5px;
    background: #d7d9dd;
  }
  .s1 {
    background: #ff0000;
  }
  .s2 {
    background: #ff8f00;
  }
  .s3 {
    background: #ffcc00;
  }
  .s4 {
    background: #00ca22;
  }
}
</style>
